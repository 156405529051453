<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="Shield Testing" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Details" size="medium" />
            <a
              :href="require('../../assets/IPSeries/ShieldTestDetails-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/ShieldTestDetails-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test determines whether an operational shield is adequate for
              protecting personnel from a maximum credible incident (accidental)
              plus a 25 percent overcharge based on the following acceptance
              criteria as outlined in MIL-STD-398:
            </p>

            <ul class="p-light mt-4 list-disc pl-8">
              <li>
                Blast Attenuation: Shields shall be designed to prevent exposure
                of operating personnel to peak positive incident pressures above
                2.3 psig.
              </li>
              <li>
                Fragmentation Confinement: Shields shall be designed to (a)
                contain all fragmentation or direct fragmentation away from
                areas requiring protection; (b) prevent generation of secondary
                fragmentation within areas requiring protection; and (c) prevent
                movement, overturning, or structural deflections which could
                result in injury.
              </li>
              <li>
                Thermal Effects Attenuation: Shields shall be designed to limit
                exposure of personnel to a critical heat flux value based on
                total time of exposure. The critical heat flux equation is as
                follows: Q = 0.62t^(-0.7423), where Q is the heat flux in
                cal/(sec cm^2) and t is the total time in seconds that a person
                is exposed to the radiant heat.
              </li>
            </ul>
            <p class="p-light mt-4">
              Blast overpressure and heat flux are measured at the center of
              probable head locations of each operator. For standing locations,
              the gauges are positioned 65 inches above the floor. For sitting
              locations, the gauges are positioned 31.5 inches above the seat.
            </p>
            <p class="p-light mt-4">
              Note that whether or not shielding is required depends on the
              process hazards analysis which incorporates the likelihood of an
              initiation event, quantity of material, personnel exposure, etc.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Configuration of substance or article</td>
                    <td>
                      Simulate a specific in-process configuration or a
                      worst-case configuration (confined, random orientation) as
                      identified in the process hazards analysis
                    </td>
                    <td>In-process configuration</td>
                    <td>
                      Articles placed in a worst-case configuration (e.g.
                      side-by-side, end-to-end, and/or in a pile) as observed or
                      possibly observed in process with a 25% overcharge
                      according to MIL-STD-398.
                    </td>
                  </tr>
                  <tr>
                    <td>Quantity of articles</td>
                    <td>
                      Simulate the in-process configuration with a 25%
                      overcharge according to MIL-STD-398
                    </td>
                    <td></td>
                    <td>
                      25% overcharge of worst-case in-process configuration
                    </td>
                  </tr>
                  <tr>
                    <td>Initiation method</td>
                    <td>
                      Initiate the substance or article in the most likely way
                      it could be initiated in the process as identified in the
                      process hazards analysis
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Operational shielding</td>
                    <td>
                      Provide necessary shielding to protect personnel and as
                      necessary comply with MIL-STD-398 requirments
                    </td>
                    <td></td>
                    <td>
                      Shield specifics and implementation verified by test data
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Pressure experienced by operator(s)</td>
                    <td>Pressure probe recorded data</td>
                    <td>Pressure should be below 2.3 psig per MIL-STD-398</td>
                  </tr>
                  <tr>
                    <td>Fragmentation</td>
                    <td>Video and visual evidence</td>
                    <td>
                      Shield should contain all fragments, prevent generation of
                      secondary fragments, and prevent deflections that could
                      cause injury per MIL-STD-398
                    </td>
                  </tr>
                  <tr>
                    <td>Heat flux experienced by operator(s)</td>
                    <td>Heat flux recorded data</td>
                    <td>
                      Heat flux should not result in personnel injury as per the
                      requirements in MIL-STD-398
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Video of Shield Containing All Fragments (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166206343?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166206343"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
